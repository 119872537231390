import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Intro from "../components/about/intro"
import Process from "../components/about/process"
import SimpleCallToAction from "../components/about/simpleCallToAction"

const Over = () => {
  const { allPagesJson } = useStaticQuery(
    graphql`
      {
        allPagesJson(filter: { page: { eq: "over" } }) {
          edges {
            node {
              title
              intro {
                heading
                keyword
                description
                image_alt
                image {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              process {
                title
                baseline
                keywords
                description
                steps {
                  title
                  icon
                }
              }
              simple_cta {
                title
                button
              }
            }
          }
        }
      }
    `
  )

  const data = allPagesJson.edges[0].node

  return (
    <Layout>
      <Seo
        title={data.title}
        description={data.intro?.description}
        image={`${data.metadata?.meta_image?.name}.${data.metadata?.meta_image?.extension}`}
      />
      <Intro data={data.intro} />
      <Process data={data.process} />
      <SimpleCallToAction data={data.simple_cta} />
    </Layout>
  )
}

export default Over
