import * as React from "react"
import {
  ChatAlt2Icon,
  ClipboardListIcon,
  CodeIcon,
  TemplateIcon,
  ClipboardCheckIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline"

const Process = ({ data }) => {
  const steps = data.steps

  const icons = {
    ChatAlt2Icon,
    ClipboardListIcon,
    CodeIcon,
    TemplateIcon,
    ClipboardCheckIcon,
    TrendingUpIcon,
  }

  const Icon = props => {
    const { name } = props

    const TheIcon = icons[name]
    return <TheIcon {...props} />
  }

  return (
    <section className="pb-32">
      <div
        className="pt-32 bg-top bg-no-repeat lg:bg-cover"
        style={{ backgroundImage: "url('../../../intersect.svg')" }}
      >
        <div className="contain">
          <div className="max-w-lg mx-auto text-center lg:mb-16">
            <h1 className="my-2 font-bold text-transparent uppercase bg-gradient bg-clip-text">
              {data.title}
            </h1>
            <h2 className="mt-4 mb-2 text-3xl font-bold lg:text-4xl font-heading">
              <span>{`${data.baseline} `}</span>
              {data.keywords.split(" ").map(word => {
                return (
                  <span className="text-transparent bg-gradient bg-clip-text">
                    {`${word} `}
                  </span>
                )
              })}
            </h2>
            <p className="leading-loose text-grayGray-400">
              {data.description}
            </p>
          </div>
          <div className="mx-auto">
            <div className="w-full">
              <div className="grid justify-center grid-cols-11 px-2 mx-auto lg:grid-cols-7 lg:px-6">
                {steps.map((step, index) => {
                  return (
                    <div className="contents">
                      <div className="relative hidden col-start-1 col-end-2 lg:block md:mx-auto">
                        <div className="flex items-center justify-center w-16 h-full">
                          <div
                            className={`w-1 bg-blue-500 pointer-events-none ${
                              index === 0 || index === steps.length - 1
                                ? "h-1/2 absolute"
                                : ""
                            } ${index === 0 ? "bottom-0" : ""} ${
                              index === steps.length - 1 ? "top-0" : ""
                            } ${
                              index !== 0 && index !== steps.length - 1
                                ? "h-full"
                                : ""
                            }`}
                          ></div>
                        </div>
                        <div className="absolute flex items-center justify-center w-16 h-16 p-3 -mt-5 font-bold rounded-full shadow-md top-1/2 bg-gradient text-gray-50 lg:text-xl">
                          <Icon name={step.icon} />
                        </div>
                      </div>
                      <div className="col-start-1 col-end-12 px-4 py-6 my-8 rounded-lg shadow-md lg:col-start-2 lg:col-end-7 bg-gradient-fade">
                        <div className="flex items-center space-x-5 text-gray-900">
                          <div className="p-4 font-bold text-transparent text-7xl bg-gradient bg-clip-text">
                            {index + 1}
                          </div>
                          <div>
                            <h3 className="mt-1 text-2xl font-extrabold text-gray-900 sm:text-xl sm:tracking-tight lg:text-4xl">
                              {step.title}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Process
