import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"

const Intro = ({ data }) => {
  const image = getImage(data.image)

  return (
    <section className="pt-16">
      <motion.div
        initial={{
          opacity: 0,
          width: 0,
        }}
        animate={{
          opacity: 1,
          width: "50%",
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          type: "spring",
          mass: 0.35,
          stiffness: 75,
          duration: 1,
          delay: 0.75,
        }}
        className="absolute inset-0 z-0 hidden w-1/2 ml-auto overflow-visible lg:block bg-gray-50 min-h-85-screen"
      ></motion.div>
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 min-h-80-screen">
        <div className="flex flex-wrap items-center -mx-3">
          <div className="w-full px-3 lg:w-1/2">
            <div className="py-12">
              <div className="max-w-lg mx-auto mb-8 text-center lg:max-w-md lg:mx-0 lg:text-left">
                <h2 className="mb-4 text-3xl font-bold md:text-4xl">
                  <span>{data.heading}</span>
                  <span className="text-transparent bg-gradient bg-clip-text">
                    {data.keyword}
                  </span>
                </h2>
                <p className="text-lg leading-relaxed text-gray-500 whitespace-pre-line">
                  {data.description}
                </p>
              </div>
              <div className="text-center lg:text-left"></div>
            </div>
          </div>
          <div className="w-full px-3 mb-12 lg:w-1/2 lg:mb-0">
            <div className="flex items-center justify-center rounded lg:h-128">
              <GatsbyImage
                image={image}
                className="rounded lazyload lg:max-w-lg"
                alt={data.image_alt}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Intro
