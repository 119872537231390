import * as React from "react"
import { Link } from "gatsby"

const simpleCallToAction = ({ data }) => {
  return (
    <section class="pt-20 pb-24 bg-gradient">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 text-center contain">
        <h2 class="mt-3 text-3xl md:text-4xl text-white font-bold font-heading">
          {data.title}
        </h2>
        <div class="mt-6">
          <Link
            to="/contact"
            class="block text-gray-50 border-b-2 transition-all duration-300 hover:pb-2 hover:px-8 sm:inline-block py-4 px-2 mb-4 mx-auto sm:mb-0 sm:mr-3 text-2xl font-bold"
          >
            {data.button}
          </Link>
        </div>
      </div>
    </section>
  )
}

export default simpleCallToAction
